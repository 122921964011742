import React, { useState } from 'react';
import "../components/header.css";
import { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    toggleMenu(); // Automatically toggle the menu when an item is clicked
  };

  return (
    <div>
      <nav>
        <div className="logo">
          <Link to="/">
            <img src="https://i.ibb.co/RHBLTXp/logo.png" alt="Logo Image" />
          </Link>
        </div>
        <div className={`hamburger ${isMenuOpen ? "toggle" : ""}`} onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
          <li><Link to="/" onClick={handleMenuItemClick}>Home</Link></li>
          <li><Link to="/aboutcompany" onClick={handleMenuItemClick}>About Us</Link></li>
          <li><Link to="/productcollection" onClick={handleMenuItemClick}>Products</Link></li>
          <li><Link to="/career" onClick={handleMenuItemClick}>Careers</Link></li>
          <li><Link to="/blogs" onClick={handleMenuItemClick}>Blogs</Link></li>
          <li><Link to="/contact" onClick={handleMenuItemClick}>Contact Us</Link></li>
          <li><a className="join-button" href="https://portal.everestpharmaceuticals.com.np/" onClick={closeMenu}>E-REPORTING</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
