import React, { useState, useEffect } from "react";
import "../components/Career.css";
import Banner from "../components/Banner";
import { createClient } from "contentful";
import Footer from "../components/Footer";
import CareerForm from "../components/CareerForm";

function Career() {
  const [positions, setPositions] = useState([]);
  const [showCareerForm, setShowCareerForm] = useState(false);

  useEffect(() => {
    const client = createClient({
      space: "j389y3dfrbxy",
      environment: "master",
      accessToken: "hxOpy1l7J9AMn9pjlGHxB_rd4UTyiTCFUJKvOa4yjV8",
    });

    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "career",
        });

        setPositions(response.items);
      } catch (error) {
        console.log("Error retrieving Contentful data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSendHereClick = () => {
    setShowCareerForm(true); // Show the CareerForm after clicking "Send Here"
  };

  return (
    <div>
      <Banner
        backgroundImage={"https://i.ibb.co/JkvD8Nq/3.png"}
        title="Career"
      />
      

      {showCareerForm ? (
        <CareerForm />
      ) : (
        <>
          <main className="wrap">
            <div className="head_career">Available Job Position</div>
            <section className={`positions${positions.length === 0 ? " center" : ""}`}>
              {positions.map((career) => (
                <article className={`position position--${career.fields.slug}`} key={career.sys.id}>
                  <header className="head">
                    <h3 className="position__name">
                      <span className="psn">Position Available:</span> {career.fields.name}
                    </h3>
                  </header>
                  <header className="head">
                    <h3 className="position__name">
                      <span className="psn">No. of Position:</span> {career.fields.openPositions}
                    </h3>
                  </header>
                  <a href="#" className="btn" onClick={handleSendHereClick}>
                    Send Here
                  </a>
                </article>
              ))}
            </section>

            {positions.length > 0 && (
              <footer className="foot">
                <p>Note: Click the button, and it will be directed to the above form and send the information and wait for our response.</p>
              </footer>
            )}
          </main>
          <CareerForm />
        </>
      )}

      <Footer />
    </div>
  );
}

export default Career;
