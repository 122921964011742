import React, { useEffect, useState } from "react";
import "../components/ProductTable.css";
import { Link } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: "j389y3dfrbxy",
  environment: "master",
  accessToken: "hxOpy1l7J9AMn9pjlGHxB_rd4UTyiTCFUJKvOa4yjV8",
});

function ProductTable({ products, searchText, selectedAlphabet, selectedCategory, selectedDivision }) {
  const [currentProducts, setCurrentProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        let response;
        let query = {
          content_type: "productPage",
          order: "fields.title",
        };

        response = await client.getEntries(query);

        let fetchedProducts = response.items;

        if (selectedAlphabet !== "All") {
          fetchedProducts = fetchedProducts.filter((product) =>
            product.fields.title
              .toLowerCase()
              .startsWith(selectedAlphabet.toLowerCase())
          );
        }

        if (searchText) {
          fetchedProducts = fetchedProducts.filter((product) =>
            product.fields.title
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        }

        if (selectedCategory !== "All") {
          fetchedProducts = fetchedProducts.filter(
            (product) => product.fields.category === selectedCategory
          );
        }

        if (selectedDivision !== "All") {
          fetchedProducts = fetchedProducts.filter(
            (product) => product.fields.division === selectedDivision
          );
        }

        fetchedProducts.sort((a, b) => {
          // Extract names and numeric values
          const nameA = a.fields.title.split(/\s+(\d+)/)[0];
          const nameB = b.fields.title.split(/\s+(\d+)/)[0];
          const numA = parseInt(a.fields.title.match(/\d+/)) || 0;
          const numB = parseInt(b.fields.title.match(/\d+/)) || 0;
        
          // Compare names
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
        
          // Names are the same, compare numeric values in reverse order
          return numB - numA;
        });

        setCurrentProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);
        // console.log("Fetched products successfully");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchProducts();
  }, [selectedAlphabet, searchText, products, selectedCategory, selectedDivision]);

  const limitCompositionName = (composition) => {
    if (composition.length > 13) {
      return composition.substring(0, 13) + "...";
    }
    return composition;
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name of Product</th>
            <th>Composition</th>
            <th>Packing</th>
            <th>Therapeutic Category</th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.map((product) => (
            <tr key={product.sys.id}>
              <td>
                <Link
                  className="product_link"
                  to={`/products/${product.sys.id}`}
                >
                  {product.fields.title}
                </Link>
              </td>
              <td>{limitCompositionName(product.fields.composition)}</td>
              <td>{product.fields.packing}</td>
              <td>{product.fields.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductTable;
